
import {
  computed, defineComponent, PropType, ref,
} from 'vue';

import { useRouter } from 'vue-router';

import { ElAutocomplete, ElTable } from 'element-plus';

import moment from 'moment';
import qs from 'qs';

import { NotesTable } from '@/components/Shared';

import useColumn from '@/uses/useColumn';
import useGrid from '@/uses/useGrid';
import useInvoice from '@/uses/useInvoice';
import useNote from '@/uses/useNote';
import useView from '@/uses/useView';

import { IColumn } from '@/interfaces/Grid';
import { INoteDimension, INoteRequest, INotesRequest } from '@/interfaces/Note';
import { IFilter } from '@/interfaces/Filter';
import { IColumnRequest } from '@/interfaces/Column';

import DataGridDrilldown from './DataGridDrilldown.vue';

interface ISortOrder {
  prop: string;
  order: string | null;
}

export default defineComponent({
  name: 'DataGrid',

  components: {
    DataGridDrilldown,
    NotesTable,
  },

  props: {
    columns: {
      type: Array as PropType<IColumn[]>,
      required: true,
    },

    totals: {
      type: Object as PropType<Record<string, number>[]>,
      required: false,
      default: null,
    },

    gridData: {
      type: Array,
      required: true,
    },

    drillThrough: {
      type: String,
      default: '',
      required: true,
    },

    hasNotes: {
      type: Boolean,
      default: false,
      required: true,
    },

    drilldowns: {
      type: Array,
      required: true,
    },
  },

  emits: [
    'sort-change',
    'toggle-drawer',
    'toggle-drilldown',
    'add-filter',
    'remove-filter',
    'refresh-data',
    'reset-column-filters',
  ],

  setup(props, { emit }) {
    const router = useRouter();

    const { columnResponse, resetColumnResponse, fetchColumnData } = useColumn();

    const { gridRequest } = useGrid();

    const { dynamicInvoiceRequest } = useInvoice();

    const {
      createNote, fetchNotes, queryNote, noteQueryResponse,
    } = useNote();

    const {
      dataSource, gridName, view, userSelection,
    } = useView();

    const searchValue = ref<string>('');

    const searchInput = ref<InstanceType<typeof ElAutocomplete>>();

    const isEditingNote = ref<boolean>(false);

    const note = ref<string>('');

    const dataRow = ref<Record<string, unknown>>({});

    const activeNoteRow = ref<Record<string, unknown>>({});

    const dataGrid = ref<InstanceType<typeof ElTable>>();

    const expandRowKeys = ref<string[]>([]);

    const rawFilters = ref<IFilter[]>([]);

    const showHeaderIcons = ref<boolean>(false);

    const activeColumn = ref<string>('');

    const operation = ref<string>('');

    const multipleSelection = ref();

    const sortOrder = ref<ISortOrder>({
      prop: '',
      order: '',
    });

    const params = (payload): URLSearchParams => new URLSearchParams(payload);

    const filters = computed(
      (): IFilter[] => [...gridRequest.value.filters],
    );

    const columnFilters = (
      columnId: string,
    ): IFilter[] => filters.value.filter((el) => el.id === columnId && el.visible);

    const columnRequest = ref<IColumnRequest>({
      dataset_name: '',
      dimensions: [{
        name: '',
      }],
      metrics: [],
      filters: [{
        name: '',
        operation: '',
        value: '',
        type: '',
      }],
      order: [],
      pagination: {
        page_size: 100,
        page_index: 0,
      },
    });

    const setColumnRequest = (): void => {
      const selectedColumn = props.columns.find(
        (column: IColumn) => column.id === activeColumn.value,
      );
      columnRequest.value.dimensions[0].name = activeColumn.value;
      columnRequest.value.filters[0].name = activeColumn.value;
      columnRequest.value.filters[0].type = selectedColumn ? selectedColumn.type : '';
      columnRequest.value.filters[0].value = searchValue.value;
      columnRequest.value.dataset_name = dataSource.value;
      if (selectedColumn && selectedColumn.type === 'money') {
        operation.value = 'in';
      } else if (selectedColumn && selectedColumn.type === 'date') {
        operation.value = 'in';
      } else {
        operation.value = 'like';
      }
      columnRequest.value.filters[0].operation = 'like';
    };

    const setActiveColumn = (value: string): void => {
      activeColumn.value = value;
      fetchColumnData(columnRequest.value);
      setColumnRequest();
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        searchInput.value?.focus();
      }, 1);
    };

    let timeout;
    const queryColumnData = (queryString: string, cb) => {
      clearTimeout(timeout);
      timeout = setTimeout(async () => {
        if (activeColumn.value === 'notes') {
          await queryNote({
            viewName: view.value.name,
            componentName: gridName.value,
            note: searchValue.value,
          });
          cb(noteQueryResponse.value.rows?.map((value) => ({
            value: Object.values(value)[0],
            label: Object.values(value)[0],
          })));
        } else {
          setColumnRequest();
          await fetchColumnData(columnRequest.value);
          cb(columnResponse.value.rows?.map((value) => ({
            value: Object.values(value)[0],
            label: Object.values(value)[0],
          })));
        }
      }, 10);
    };

    const onSearch = (id: string, type: string): void => {
      if (searchValue.value && searchValue.value !== '') {
        expandRowKeys.value = [];
        emit('add-filter', {
          id,
          type,
          value: type === 'date'
            ? moment.utc(searchValue.value).format('YYYY-MM-DD') : searchValue.value,
          operation: operation.value,
        });
      }

      searchValue.value = '';
    };

    const removeFilter = (filterId): void => {
      expandRowKeys.value = [];
      emit('remove-filter', filterId);
    };

    const clearFilter = (): void => {
      resetColumnResponse();
      searchValue.value = '';
    };

    const editNote = (row, column): void => {
      if (column.property === 'notes') {
        isEditingNote.value = true;
        note.value = row.notes ? row.notes : '';
        activeNoteRow.value = row;
      }
    };

    const toggleDrilldown = (payload): void => {
      if (
        expandRowKeys.value
        && expandRowKeys.value.length > 0
        && payload.id === expandRowKeys.value[0]
      ) {
        expandRowKeys.value = [];
      } else {
        expandRowKeys.value = [payload.id];
      }
      dataRow.value = payload;
    };

    const setDrilldownDimensions = (payload): void => {
      dataRow.value = payload;
    };

    const getSummaries = (param) => {
      const { columns } = param;
      const sums: string[] = [];
      if (props.totals) {
        const summableColumns = Object.keys(props.totals);
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '';
            return;
          }
          if (summableColumns.includes(column.property)) {
            sums[index] = `${props.columns[index - 3]?.prefix ?? ''} ${Number(
              props.totals[column.property],
            ).toFixed(2)}`;
          } else {
            sums[index] = '';
          }
        });
      }
      return sums;
    };

    const getNotes = async (payload: Record<string, unknown>): Promise<void> => {
      try {
        const dimensionIds = props.columns
          .filter((column: IColumn) => column.column_type === 'dimension')
          .map((el) => el.id);
        const dimensions: INoteDimension[] = Object.entries(payload)
          .filter(([key]) => dimensionIds.find((el) => el === key))
          .map(([key, value]) => ({
            dimension_name: key,
            dimension_value: value,
          }));
        const notesRequest: INotesRequest = {
          organization_id: '1',
          dealer_id: '1',
          note_dimensions: dimensions,
        };
        await fetchNotes(notesRequest);
      } catch (error) {
        console.error(error);
      }
    };

    const saveNote = async (previousValue): Promise<void> => {
      try {
        if (view.value.name === 'invoices' && previousValue !== note.value) {
          await dynamicInvoiceRequest({
            url: '/actionhub',
            method: 'POST',
            data: {
              note: note.value,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              invoice_id: [activeNoteRow.value.invoice_id.toString()],
              command: 'set_invoices_note',
            },
          });
        } else {
          const dimensionIds = props.columns
            .filter((column: IColumn) => column.column_type === 'dimension')
            .map((el) => el.id);

          const dimensions: INoteDimension[] = Object.entries(activeNoteRow.value)
            .filter(([key]) => dimensionIds.find((el) => el === key))
            .map(([key, value]) => ({
              dimension_name: key,
              dimension_value: value,
            }));

          const noteRequest: INoteRequest = {
            organization_id: '1',
            dealer_id: '1',
            note_dimensions: dimensions,
            note: note.value,
          };
          await createNote(noteRequest);
        }
      } catch (error) {
        console.error(error);
      } finally {
        emit('refresh-data');
        isEditingNote.value = false;
        note.value = '';
        activeNoteRow.value = {};
      }
    };

    const resetColumnFilters = (columnId: string): void => {
      expandRowKeys.value = [];
      emit('reset-column-filters', columnId);
    };

    const changeSort = (sort) => {
      sortOrder.value.prop = sort.prop;
      sortOrder.value.order = sort.order;
      emit('sort-change', sort);
    };

    const setDefaultSort = (sort) => {
      sortOrder.value.prop = sort.id;
      sortOrder.value.order = sort.type === 'asc' ? 'ascending' : 'descending';
    };

    const handleSelectionChange = (val) => {
      multipleSelection.value = val;
    };

    const clearMultipleSelection = () => {
      // eslint-disable-next-line no-unused-expressions
      dataGrid.value?.clearSelection();
    };

    const redirectToSingleView = (value, columnDef) => {
      const rawQueryParams = {
        dataSource: dataSource.value,
        column: columnDef,
      };

      const queryParams = qs.stringify(rawQueryParams);
      const newRoute = router.resolve(`/invoices/${value}?${queryParams}`);
      window.open(newRoute.href);
    };

    const resetDataGrid = () => {
      window.location.reload();
    };

    const elements = document.getElementsByClassName('el-table__body-wrapper');
    const main = document.getElementsByClassName('el-main');

    const formatCurrency = (value?: string | number) => {
      if (typeof value === 'number') {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
          value,
        );
      }

      if (!value) {
        return '';
      }

      if (!value.toString().includes('$')) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
          Number(value),
        );
      }
      return value;
    };

    return {
      elements,
      main,
      gridName,
      searchValue,
      searchInput,
      isEditingNote,
      note,
      dataGrid,
      dataRow,
      expandRowKeys,
      activeNoteRow,
      showHeaderIcons,
      activeColumn,
      rawFilters,
      sortOrder,
      columnResponse,
      gridRequest,
      userSelection,
      multipleSelection,
      params,
      removeFilter,
      columnFilters,
      onSearch,
      editNote,
      toggleDrilldown,
      setDrilldownDimensions,
      getSummaries,
      getNotes,
      saveNote,
      resetColumnFilters,
      queryColumnData,
      setActiveColumn,
      changeSort,
      setDefaultSort,
      clearFilter,
      handleSelectionChange,
      clearMultipleSelection,
      redirectToSingleView,
      resetDataGrid,
      moment,
      formatCurrency,
    };
  },

  watch: {
    '$route.query': {
      handler() {
        this.expandRowKeys = [];
      },

      deep: true,
    },
  },
});
